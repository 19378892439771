import React, { useState, useEffect } from "react";
import "../../style/style.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import fetchOrder from "../../api/fetchOrder";
import fetchSubscriptionmeta from "../../api/fetchSubscriptionmeta";
import fetchSubscriptionbytype from "../../api/fetchSubscriptionbytype";
import fetchEbooks from "../../api/fetchEbook";
import fetchChallenge from "../../api/fetchChallenge";
import fetchTraining from "../../api/fetchTrening";
import fetchUser from "../../api/fetchUser";
import fetchSubscriptionmetabyuser from "../../api/fetchSubscriptionmetabyuser";
import { useNavigate } from "react-router-dom";

const Checkout = ({ user }) => {
  const { id, category } = useParams();
  const [productData, setProductData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [pdvAmount, setPdvAmount] = useState(null);
  const [amount, setAmount] = useState(null);
  const [saveAmount, setSaveAmount] = useState(null);
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/emailregister`,
        formData
      );
      if (response.data.status === "success") {
        setMessage("Registracija uspješna!");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("accessPlatform");
        localStorage.setItem("accessToken", response.data.data.token); // Spremanje tokena u localStorage
        localStorage.setItem("accessPlatform", "email");

        setIsLoggedIn(true);
        // Fetch the user data after successful registration
        const userDataResponse = await fetchUser.get(
          `/${response.data.data.user.id}`
        );
        setUserData(userDataResponse.data.data.user);

        setTimeout(() => {
          handlePayment(userDataResponse.data.data.user);
        }, 3000);
      } else {
        setMessage("Registracija nije uspjela.");
      }
    } catch (error) {
      setMessage("Došlo je do greške prilikom registracije.");
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        switch (category) {
          case "ebook":
            response = await fetchEbooks.get(`/${id}`);
            break;
          case "challenge":
            response = await fetchChallenge.get(`/${id}`);
            break;
          case "training":
            response = await fetchTraining.get(`/${id}`);
            break;
          case "subscription":
            response = await fetchSubscriptionbytype.get(`/${id}`);
            break;
          default:
            throw new Error("Unsupported category");
        }
        setProductData(response.data.data[category]);

        if (user && user.id) {
          const userDataResponse = await fetchUser.get(`/${user.id}`);
          setUserData(userDataResponse.data.data.user);
        }
      } catch (err) {
        console.error(`Error fetching ${category}:`, err);
      }
    };

    fetchData();
  }, [id, category, user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const responseUser = await fetchUser.get(`/${user.id}`);
          setUserData(responseUser.data.data.user);
          const responseSubscription = await fetchSubscriptionmetabyuser.get(
            `/${user.id}`
          );
          setSubscriptionData(responseSubscription.data.data.subscription);
        }
      } catch (err) {}
    };
    fetchData();
  }, [user]);

  useEffect(() => {
    const calculateAmount = () => {
      if (subscriptionData) {
        if (category === "subscription") {
        } else if (productData && productData.price != null) {
          const amount = (productData.price - productData.premium_save).toFixed(
            2
          );
          const saveAmount = (productData.price - amount).toFixed(2);
          setAmount(amount);
          setSaveAmount(saveAmount);
        } else {
          console.error("Product data is missing or incomplete.");
        }
      } else if (productData && productData.price != null) {
        const amount = productData.price;
        setAmount(amount);
      } else {
        console.error("Product data is missing or incomplete.");
      }
    };
    calculateAmount();
  }, [productData, subscriptionData]);

  const handlePayment = async (userData) => {
    try {
      const orderData = {
        date_created: new Date().toISOString(),
        date_paid: new Date().toISOString(),
        status: "created",
        total: parseFloat(amount),
        subtotal: parseFloat(amount - pdvAmount),
        tax: parseFloat(pdvAmount),
        user_id: userData.id,
        product_category: category,
        product_id: productData.id,
      };

      const responseOrder = await fetchOrder.post("/", orderData);

      const corvusPayData = {
        store_id: "25630",
        order_number: responseOrder.data.data.order.id,
        language: "hr",
        currency: "EUR",
        amount: responseOrder.data.data.order.total,
        cart: productData.title,
        require_complete: false,
        version: "1.4",
        cardholder_name: userData.first_name,
        cardholder_surname: userData.last_name,
        cardholder_email: userData.email,
      };

      if (category === "subscription") {
        corvusPayData.subscription = true;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/corvussignature`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(corvusPayData),
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          const signature = responseData.signature;

          const redirectToCorvusPay = () => {
            const form = document.createElement("form");
            form.method = "post";
            form.action = "https://wallet.corvuspay.com/checkout/";

            const params = {
              store_id: "25630",
              order_number: responseOrder.data.data.order.id,
              language: "hr",
              currency: "EUR",
              amount: responseOrder.data.data.order.total,
              cart: productData.title,
              require_complete: false,
              version: "1.4",
              signature: signature,
              cardholder_name: userData.first_name,
              cardholder_surname: userData.last_name,
              cardholder_email: userData.email,
            };

            if (category === "subscription") {
              params.subscription = true;
            }

            Object.keys(params).forEach((key) => {
              const input = document.createElement("input");
              input.type = "hidden";
              input.name = key;
              input.value = params[key];
              form.appendChild(input);
            });

            document.body.appendChild(form);
            form.submit();
          };

          redirectToCorvusPay();
        } else {
          console.error("Server error:", response.status, response.statusText);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    } catch (error) {
      console.error("Error creating order or invoice:", error);
    }
  };

  return (
    <div>
      <section>
        <h1 className="sr-only">Checkout</h1>

        <div className="relative mx-auto max-w-screen-2xl">
          <div className="grid grid-cols-1 md:grid-cols-2 min-h-[100vh]">
            {productData ? (
              <div className="bg-zinc-800 py-12 md:py-24">
                <div className="mx-auto max-w-xl px-4 lg:px-8">
                  <div className="flex items-center">
                    {userData ? (
                      <>
                        <div
                          tabIndex="0"
                          className="focus:outline-none h-12 w-12 max-w-[50%]"
                        >
                          <img
                            src={userData.picture}
                            alt="man avatar"
                            className="h-full w-full rounded-full overflow-hidden shadow"
                          />
                        </div>
                        <h2 className="font-semibold ml-4 text-white">
                          {userData.first_name} {userData.last_name}
                        </h2>
                      </>
                    ) : null}
                  </div>

                  <div className="mt-6 pt-6 border-t">
                    <div className="flow-root">
                      <ul className="-my-4 divide-y divide-gray-200">
                        <li className="flex items-center justify-between py-4">
                          <div className="flex items-center">
                            <img
                              alt="Lettuce"
                              src="https://hips.hearstapps.com/hmg-prod/images/she-stays-fit-because-she-stretches-royalty-free-image-1601654317.jpg"
                              className="aspect-video w-36 flex-shrink-0 rounded-lg object-cover hidden"
                            />

                            <div className="">
                              <p className="text-crimson text-xl font-semibold leading-6 text-white">
                                {productData.title}
                              </p>

                              <dl className="mt-1 space-y-1 text-sm text-zinc-400">
                                <div>
                                  <dt className="inline capitalize">
                                    {category}
                                  </dt>
                                </div>
                              </dl>
                            </div>
                          </div>
                          <div>
                            <p className="text-lg font-semibold text-white">
                              €{productData.price}
                            </p>
                          </div>
                        </li>
                        {subscriptionData && category !== "subscription" ? (
                          <li className="flex items-center justify-between py-4">
                            <div className="flex items-center">
                              <img
                                alt="Lettuce"
                                src="https://hips.hearstapps.com/hmg-prod/images/she-stays-fit-because-she-stretches-royalty-free-image-1601654317.jpg"
                                className="aspect-video w-36 flex-shrink-0 rounded-lg object-cover hidden"
                              />

                              <div className="">
                                <p className="text-crimson text-xl font-semibold leading-6 text-white">
                                  Premium Član Popust
                                </p>
                              </div>
                            </div>
                            <div>
                              <p className="text-lg font-semibold text-white">
                                - €{saveAmount}
                              </p>
                            </div>
                          </li>
                        ) : (
                          <></>
                        )}
                        <li className="flex items-center justify-between py-4">
                          <div className="flex items-center">
                            <img
                              alt="Lettuce"
                              src="https://hips.hearstapps.com/hmg-prod/images/she-stays-fit-because-she-stretches-royalty-free-image-1601654317.jpg"
                              className="aspect-video w-36 flex-shrink-0 rounded-lg object-cover hidden"
                            />
                            <div className="">
                              <p className="text-crimson text-xl font-semibold leading-6 text-white">
                                Ukupno
                              </p>
                            </div>
                          </div>
                          <div>
                            <p className="text-lg font-semibold text-white flex justify-end ">
                              €{amount}
                            </p>
                            <dl className="hidden mt-1 space-y-1 text-sm text-zinc-400">
                              <div>
                                <dt className="inline">PDV: €{pdvAmount}</dt>
                              </div>
                            </dl>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p>Loading...</p>
            )}

            <div className="bg-zinc-900 py-12 md:py-18">
              <div className="mx-auto max-w-lg px-4 lg:px-8">
                {!userData ? (
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="flex flex-col text-white text-center justify-center items-center mb-6">
                      <h2 className="text-crimson font-bold text-4xl mb-4">
                        Kreirajte svoj račun
                      </h2>
                      <span className="text-sm">
                        Za pristup vašim kupnjama potreban je račun.
                      </span>
                      <span className="text-sm">
                        Već imate račun?{" "}
                        <a className="font-bold" href="/app/login">
                          Prijavite se
                        </a>
                      </span>
                    </div>
                    <div className="pt-6">
                      <label
                        htmlFor="first_name"
                        className="relative block overflow-hidden rounded-md bg-zinc-800 border border-zinc-600 px-3 pt-3 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
                      >
                        <input
                          className="peer h-8 w-full border-none text-white bg-zinc-800 p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                          placeholder="First Name"
                          type="text"
                          id="first_name"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                        />
                        <span className="absolute left-3 top-2 -translate-y-1/2 text-xs text-zinc-500 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs">
                          Ime
                        </span>
                      </label>
                    </div>

                    <div>
                      <label
                        htmlFor="last_name"
                        className="relative block overflow-hidden rounded-md bg-zinc-800 border border-zinc-600 px-3 pt-3 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
                      >
                        <input
                          className="peer h-8 w-full border-none text-white bg-zinc-800 p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                          placeholder="Last Name"
                          type="text"
                          id="last_name"
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleChange}
                        />
                        <span className="absolute left-3 top-2 -translate-y-1/2 text-xs text-zinc-500 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs">
                          Prezime
                        </span>
                      </label>
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="relative block overflow-hidden rounded-md bg-zinc-800 border border-zinc-600 px-3 pt-3 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
                      >
                        <input
                          className="peer h-8 w-full border-none text-white bg-zinc-800 p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                          placeholder="Email"
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        <span className="absolute left-3 top-2 -translate-y-1/2 text-xs text-zinc-500 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs">
                          Email
                        </span>
                      </label>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="relative block overflow-hidden rounded-md bg-zinc-800 border border-zinc-600 px-3 pt-3 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
                      >
                        <input
                          className="peer h-8 w-full border-none text-white bg-zinc-800 p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                          placeholder="Password"
                          type="password"
                          id="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <span className="absolute left-3 top-2 -translate-y-1/2 text-xs text-zinc-500 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs">
                          Lozinka
                        </span>
                      </label>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="block w-full text-center font-bold m-auto px-12 py-3 text-sm border border-black rounded-lg bg-white text-black"
                      >
                        Registriraj se i plati
                      </button>
                      {message && (
                        <p
                          className={`${
                            message.includes("uspješna")
                              ? "text-green-500"
                              : "text-red-500"
                          } mt-2`}
                        >
                          {message}
                        </p>
                      )}
                    </div>
                  </form>
                ) : null}
                {userData ? (
                  <>
                    <h1 className="text-crimson mb-4 text-4xl font-bold leading-none tracking-tight md:text-4xl text-white hidden md:block">
                      Checkout
                    </h1>
                    <div className="col-span-3">
                      <label
                        htmlFor="firstname"
                        className="relative block overflow-hidden rounded-md bg-zinc-800 border border-zinc-600 px-3 pt-3 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
                      >
                        <input
                          disabled
                          type="text"
                          id="firstname"
                          placeholder="First Name"
                          value={userData.first_name}
                          className="peer h-8 w-full border-none text-white bg-zinc-800 p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                        />

                        <span className="absolute left-3 top-2 -translate-y-1/2 text-xs text-zinc-500 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs">
                          Ime
                        </span>
                      </label>
                    </div>
                    <div className="mt-4 col-span-3">
                      <label
                        htmlFor="lastname"
                        className="relative block overflow-hidden rounded-md bg-zinc-800 border border-zinc-600 px-3 pt-3 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
                      >
                        <input
                          disabled
                          type="text"
                          id="lastname"
                          placeholder="Last Name"
                          value={userData.last_name}
                          className="peer h-8 w-full border-none text-white bg-zinc-800 p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                        />

                        <span className="absolute left-3 top-2 -translate-y-1/2 text-xs text-zinc-500 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs">
                          Prezime
                        </span>
                      </label>
                    </div>

                    <div className="mt-4 col-span-6">
                      <label
                        htmlFor="email"
                        className="relative block overflow-hidden rounded-md bg-zinc-800 border border-zinc-600 px-3 pt-3 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
                      >
                        <input
                          disabled
                          type="email"
                          id="email"
                          placeholder="Email"
                          value={userData.email}
                          className="peer h-8 w-full border-none text-white bg-zinc-800 p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                        />

                        <span className="absolute left-3 top-2 -translate-y-1/2 text-xs text-zinc-500 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs">
                          Email
                        </span>
                      </label>
                    </div>
                    <button
                      className="mt-4 block w-full text-center font-bold m-auto px-12 py-3 text-sm border border-black rounded-lg bg-white text-black"
                      onClick={() => handlePayment(userData)}
                    >
                      Nastavi na plaćanje
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Checkout;
