import React, { useState } from "react";
import "../../style/style.css";
import { Button, Modal } from "flowbite-react";
import SubscriptionModal from "./SubscriptionModal";

const SubscriptionPromo = ({ user }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="flex flex-col md:flex-row gap-6 items-center">
      <div className="h-full md:h-96 overflow-hidden rounded-lg md:shadow-lg flex justify-center">
        <img
          className="h-96 w-auto md:h-full md:w-full object-cover"
          src="https://medianfiles.com/wp-content/uploads/2024/10/WhatsApp-Image-2024-10-27-at-18.29.41.jpeg"
          alt="Booty Challenge"
        />
      </div>

      <div className="p-4 md:p-6 w-full lg:w-96">
        <div className="flex gap-2">
          <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-button text-white">
            Popularno
          </span>

          <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-button text-white">
            HOME
          </span>
        </div>
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold uppercase text-white z-50 mt-4">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold uppercase text-white z-50 mt-4">
            Postani <span>Član</span>
          </h1>
        </h1>

        <p className="text-base text-white block mt-4">
          <strong>
            Treniraj kod kuće uz Better You treninge i ostvari odlične
            rezultate!
          </strong>
        </p>

        <p className="text-base text-white block mt-4">
          Članarina uključuje pristup preko 80+ treninga, razne izazove,
          kuharice, planove prehrane, popuste partnera i razne druge pogodnosti.
          Treninzi su namjenjeni svima bilo da ste početnik ili napredni vježbač
          te obuhvaćaju cijelo tijelo.
        </p>
        <div className="mt-6">
          <span className="text-white flex items-center gap-1 text-base">
            Cijena članarine od{" "}
            <div className="flex gap-1">
              <span className="font-semibold">€15.83</span>
            </div>
            mjesečno
          </span>
          <Button
            onClick={() => setOpenModal(true)}
            className="mt-4 text-center mx-auto inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-button rounded-lg hover:bg-button focus:ring-4 focus:outline-none focus:ring-blue-300 w-full md:w-auto"
          >
            Pretplati se
            <svg
              className="w-3.5 h-3.5 ml-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </Button>
        </div>
      </div>
      <SubscriptionModal
        user={user}
        show={openModal}
        onClose={() => setOpenModal(false)}
      />
    </div>
  );
};

export default SubscriptionPromo;
