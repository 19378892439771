import React from "react";

const BootyChallenge = ({ bootyPurchaseData2 }) => {
  return (
    <div className="flex flex-col md:flex-row gap-6 items-center">
      <div className="h-full md:h-96 overflow-hidden rounded-lg md:shadow-lg flex justify-center">
        <img
          className="h-96 w-auto md:h-full md:w-full object-cover"
          src="https://medianfiles.com/wp-content/uploads/2024/03/WhatsApp-Image-2024-03-22-at-08.28.00.jpeg"
          alt="Booty Challenge"
        />
      </div>

      <div className="p-4 md:p-6 w-full lg:w-96">
        <div className="flex flex-wrap gap-2 mb-4">
          <span className="text-xs font-medium px-2.5 py-1 rounded bg-button text-white">
            Popularno
          </span>
          <span className="text-xs font-medium px-2.5 py-1 rounded bg-button text-white">
            GYM
          </span>
        </div>
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold uppercase text-white z-50 mt-4">
          Booty <span>Challenge</span>
        </h1>

        <p className="text-sm md:text-base text-white mt-4">
          8-tjedni program treninga za teretanu koji uključuje donji dio tijela
          sa fokusom na stražnjicu. Program je namjenjen svima bilo da ste
          početnik ili napredni vježbač. Uključuje 4 treninga tjedno s
          demonstracijom vježbi te uključuje i vježbe za gornji dio tijela i
          core.
        </p>
        {bootyPurchaseData2 == null && (
          <div className="mt-6">
            <div className="flex items-center gap-2">
              <svg
                className="w-5 h-5 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7c0-1.1.9-2 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6c.6 0 1 .4 1 1v3a1 1 0 1 1-2 0v-3c0-.6.4-1 1-1Z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-white text-lg font-semibold">€40.00</span>
            </div>
            <span className="text-white text-sm md:text-base flex items-center gap-1 mt-2">
              Cijena za članove: <span className="font-semibold">€24.00</span>
            </span>
            <div className="flex flex-col sm:flex-row sm:space-x-4 mt-6">
              <a
                href="/app/checkout/training/2"
                className="inline-flex justify-center items-center py-3 px-5 text-sm md:text-base font-medium text-center rounded-lg border border-transparent focus:ring-4 focus:ring-amber-300 bg-white text-white bg-button hover:bg-gray-100"
              >
                Kupi odmah
              </a>
            </div>
          </div>
        )}
        {bootyPurchaseData2 != null && (
          <div className="mt-6 flex flex-col sm:flex-row sm:space-x-4">
            <a
              href="/app/trainings/2"
              className="inline-flex justify-center items-center py-3 px-5 text-sm md:text-base font-medium text-center rounded-lg border border-transparent focus:ring-4 focus:ring-amber-300 bg-white text-black hover:bg-gray-100"
            >
              Vidi odmah
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default BootyChallenge;
