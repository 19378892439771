// ChallengeList.jsx
import React from "react";
import "../../style/style.css";
import ChallengeList from "./ChallengeList";

const Challenges = ({ user }) => {
  return (
    <div>
      <section className="bg-zinc-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <h2 className="text-crimson mb-4 mb-8 text-4xl font-bold leading-none tracking-tight md:text-4xl text-white">
            Treninzi
          </h2>
          <ChallengeList user={user} />
        </div>
      </section>
    </div>
  );
};

export default Challenges;
