import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../style/style.css";

import TreningList from "../Trainings/TrainingList";
import ChallengeList from "../Challenges/ChallengeList";
import EbookList from "../Ebooks/EbookList";
import SubscriptionBanner from "../Subscription/SubscriptionBanner";
import PartnerPromoCodes from "./PartnerPromoCodes";
import BootyChallenge from "../Challenges/BootyChallange";
import BillingAlert from "../Notifications/BillingAlert";

import { Carousel } from "flowbite-react";

const Dashboard = ({ user }) => {
  const [bootyPurchaseData1, setBootyPurchaseData1] = useState(null);
  const [bootyPurchaseData2, setBootyPurchaseData2] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const purchasedResponse1 = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/user/${user.id}/purchased?product_id=16&product_category=challenge`
        );
        if (purchasedResponse1.data.status === "success") {
          // Ako je status uspešan, sačuvajte podatke u state
          setBootyPurchaseData1(purchasedResponse1.data);
        }
      } catch (err) {
        console.error("Error fetching challenge:", err);
      }
    };

    fetchData();
  }, [user.id]); // Ako želite da se efekat pokrene ponovo kada se promeni user.id, dodajte ga u zavisnosti

  useEffect(() => {
    const fetchData = async () => {
      try {
        const purchasedResponse2 = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/user/${user.id}/purchased?product_id=2&product_category=training`
        );
        if (purchasedResponse2.data.status === "success") {
          // Ako je status uspešan, sačuvajte podatke u state
          setBootyPurchaseData2(purchasedResponse2.data);
        }
      } catch (err) {
        console.error("Error fetching training:", err);
      }
    };

    fetchData();
  }, [user.id]); // Ako želite da se efekat pokrene ponovo kada se promeni user.id, dodajte ga u zavisnosti

  return (
    <div>
      <section className="relative w-full bg-cover bg-center">
        <div className="mx-auto w-screen max-w-screen-xl">
          <BillingAlert user={user} />
          <BootyChallenge bootyPurchaseData2={bootyPurchaseData2} />
        </div>

        <div className="hidden absolute w-full h-96 bottom-0 bg-gradient-to-t from-zinc-900"></div>
        <div className="hidden absolute w-full h-96 top-0 bg-gradient-to-b from-zinc-900"></div>
      </section>
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:px-6 mt-16">
        <TreningList />
      </div>
      <SubscriptionBanner user={user} />
      <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
        <h2 className="text-anton mt-12 mb-8 text-4xl md:text-4xl text-white">
          Treninzi
        </h2>
        <ChallengeList user={user} />
      </div>
      <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
        <h2 className="text-anton mt-12 mb-8 text-4xl md:text-4xl text-white">
          Ebooks
        </h2>
        <EbookList />
      </div>
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 shadow">
        <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
          <Carousel slideInterval={2500}>
            <img
              src="https://medianfiles.com/wp-content/uploads/2024/03/WhatsApp-Image-2024-02-17-at-14.15.47.jpeg"
              alt="..."
            />
          </Carousel>
        </div>
      </div>
      <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
        <PartnerPromoCodes user={user} />
      </div>
    </div>
  );
};

export default Dashboard;
