import React, { useState, useEffect } from "react";
import "../../style/style.css";
import { useParams } from "react-router-dom";
import fetchTraining from "../../api/fetchTrening";
import fetchSubscriptionmetabyuser from "../../api/fetchSubscriptionmetabyuser";
import fetchFile from "../../api/fetchFile";
import fetchMediatoken from "../../api/fetchMediatoken";
import axios from "axios";
import { Accordion, Button, Modal } from "flowbite-react";
import { useNavigate } from "react-router-dom";

import TrainingComments from "./TrainingComments";

import SubscriptionModal from "../Subscription/SubscriptionModal";

const TrainingDetail = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [trainingData, setTrainingData] = useState(null);
  const [trainingSectionData, setTrainingSectionData] = useState(null);
  const [filesDataArray, setFilesDataArray] = useState([]);
  const [isPurchased, setIsPurchased] = useState(false);
  const [withSubscription, setWithSubscription] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchTraining.get(`/${id}`);
        const { training, sections } = response.data.data;
        setTrainingData(training);
        setTrainingSectionData(sections);

        let isPurchased = false;

        if (training.with_subscription) {
          try {
            const responseSubscription = await fetchSubscriptionmetabyuser.get(
              `/${user.id}`
            );
            if (responseSubscription.data.status === "success") {
              setSubscriptionData(responseSubscription.data.data.subscription);
              isPurchased = true;
            }
          } catch (error) {
            // Handle subscription fetch error
          }
        } else {
          // Check if the user has purchased the training
          const purchasedResponse = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/v1/user/${user.id}/purchased?product_id=${id}&product_category=training`
          );

          if (purchasedResponse.data.status === "success") {
            const datePaidString = purchasedResponse.data.results[0].date_paid;
            const datePaid = new Date(datePaidString);
            const duration = training.duration;
            const dateDue = new Date(datePaid);
            dateDue.setDate(datePaid.getDate() + duration);
            const currentDate = new Date();
            isPurchased = currentDate <= dateDue;
          }
        }
        setIsPurchased(isPurchased);
      } catch (err) {
        console.error("Error fetching training:", err);
      }
    };

    fetchData();
  }, [id, user]);

  useEffect(() => {
    const fetchFilesData = async () => {
      try {
        if (trainingData && trainingData.files_ids) {
          const filesArray = trainingData.files_ids
            .replace(/[{}"]/g, "")
            .split(",");

          const fetchedFilesDataArray = await Promise.all(
            filesArray.map(async (fileId) => {
              const fileResponse = await fetchFile.get(`/${fileId}`);
              return fileResponse.data.data.file;
            })
          );

          setFilesDataArray(fetchedFilesDataArray);
        }
      } catch (err) {
        console.error("Error fetching files data:", err);
      }
    };

    fetchFilesData();
  }, [trainingData]);

  const openPdf = async (fileID) => {
    try {
      const response = await fetchMediatoken.get(`/${user.id}/${fileID}`);
      const mediaToken = response.data.mediatoken;
      if (mediaToken) {
        // Redirekcija na /app/player/${video.id}/?mediatoken=
        navigate(`/app/pdf/${fileID}/${mediaToken}`);
      } else {
        console.error("Video token is not generated.");
      }
    } catch (error) {
      console.error("Error generating video token:", error);
    }
  };

  return (
    <div>
      {trainingData ? (
        <div>
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="flex flex-col md:flex-row md:justify-between">
              <div className="md:w-[69%]">
                <div className="relative pt-[56.25%] rounded-lg">
                  <iframe
                    src="https://iframe.mediadelivery.net/embed/59823/26edf82c-da9a-43a2-92f4-74e1b555b899?autoplay=true&loop=true&muted=true&preload=true"
                    loading="lazy"
                    className="rounded-lg"
                    style={{
                      border: "0",
                      position: "absolute",
                      top: "0",
                      height: "100%",
                      width: "100%",
                    }}
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="py-6">
                  <nav className="flex mb-4" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                      <li className="inline-flex items-center">
                        <a
                          href="#"
                          className="inline-flex items-center text-sm font-medium text-zinc-700 hover:text-blue-600"
                        >
                          <svg
                            className="w-3 h-3 mr-2.5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                          </svg>
                          Početna
                        </a>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <svg
                            className="w-3 h-3 text-zinc-400 mx-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                          <a
                            href="#"
                            className="ml-1 text-sm font-medium text-zinc-700 hover:text-blue-600 md:ml-2"
                          >
                            Challange
                          </a>
                        </div>
                      </li>
                      <li aria-current="page">
                        <div className="flex items-center">
                          <svg
                            className="w-3 h-3 text-zinc-400 mx-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                          <span className="ml-1 text-sm font-medium md:ml-2 text-zinc-400">
                            {trainingData.title}
                          </span>
                        </div>
                      </li>
                    </ol>
                  </nav>
                  <h2 className="text-crimson mb-4 text-3xl font-extrabold leading-none tracking-tight md:text-4xl text-white">
                    {trainingData.title}
                  </h2>
                </div>
                <div>
                  <p className="mb-3 text-zinc-400">
                    {trainingData.description}
                  </p>
                </div>
                {isPurchased ? (
                  <Accordion className="mt-12 rounded-none">
                    {trainingSectionData &&
                      trainingSectionData.map((section) => (
                        <Accordion.Panel key={section.id}>
                          <Accordion.Title className="p-5 ring-0 hover:ring-0 focus:ring-0 text-white bg-zinc-800 hover:bg-zinc-700">
                            <div className="flex flex-col">
                              <span className="text-xl font-semibold">
                                {section.title}
                              </span>
                            </div>
                          </Accordion.Title>
                          <Accordion.Content className="p-5">
                            <Accordion className="rounded-none border-0 flex flex-col gap-4 divide-y-0">
                              {section.subsections &&
                                section.subsections
                                  .sort((a, b) => a.day - b.day)
                                  .map((subsection) => (
                                    <Accordion.Panel key={subsection.id}>
                                      <Accordion.Title className="p-5 ring-0 hover:ring-0 focus:ring-0 text-white bg-zinc-800 hover:bg-zinc-700 border-0 rounded-lg">
                                        <div className="flex flex-col">
                                          <span className="text-xl font-semibold">
                                            {subsection.title}
                                          </span>
                                          <span className="text-sm text-zinc-400">
                                            {`${section.title} • Dan ${subsection.day}`}
                                          </span>
                                        </div>
                                      </Accordion.Title>
                                      <Accordion.Content className="px-0 pl-4 lg:px-5 py-0">
                                        {subsection.exercises &&
                                          subsection.exercises
                                            .sort((a, b) => a.id - b.id)
                                            .map((exercise) => (
                                              <div
                                                key={exercise.id}
                                                className="divide-y divide-zinc-600"
                                              >
                                                <div className="flex justify-between items-center py-2">
                                                  <div className="flex items-center gap-8">
                                                    <a
                                                      href="#"
                                                      className="hidden lg:block"
                                                    >
                                                      <img
                                                        className="rounded-lg w-16 h-16 object-cover"
                                                        src={`https://vz-4b5814f0-f31.b-cdn.net/${exercise.video_id}/thumbnail.jpg`}
                                                        alt=""
                                                      />
                                                    </a>
                                                    <a href="#">
                                                      <h5 className="text-lg font-semibold tracking-tight text-white">
                                                        {exercise.title}
                                                      </h5>
                                                      <div className="flex gap-1">
                                                        {exercise.sets && (
                                                          <span className="text-zinc-400 text-sm">
                                                            Serija:{" "}
                                                            {exercise.sets}
                                                          </span>
                                                        )}

                                                        {exercise.sets &&
                                                          exercise.reps && (
                                                            <span className="text-zinc-400 text-sm">
                                                              •
                                                            </span>
                                                          )}

                                                        {exercise.reps && (
                                                          <span className="text-zinc-400 text-sm">
                                                            Ponavljanja:{" "}
                                                            {exercise.reps}
                                                          </span>
                                                        )}
                                                        {exercise.reps &&
                                                          exercise.weight && (
                                                            <span className="text-zinc-400 text-sm">
                                                              •
                                                            </span>
                                                          )}

                                                        {exercise.weight && (
                                                          <span className="text-zinc-400 text-sm">
                                                            Opterećenje:{" "}
                                                            {exercise.weight}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </a>
                                                  </div>
                                                  <Button
                                                    onClick={() =>
                                                      setOpenModal(exercise.id)
                                                    }
                                                    className="p-0 flex items-center justify-center bg-zinc-900 lg:bg-zinc-800 hover:bg-zinc-700 lg:rounded-lg cursor-pointer border-0 enabled:hover:bg-zinc-700 focus:ring-white"
                                                  >
                                                    <div className="bg-zinc-200 rounded-full w-8 h-8 flex items-center justify-center mr-2">
                                                      <svg
                                                        className="w-3 h-3 text-gray-800"
                                                        aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        viewBox="0 0 14 16"
                                                      >
                                                        <path d="M0 .984v14.032a1 1 0 0 0 1.506.845l12.006-7.016a.974.974 0 0 0 0-1.69L1.506.139A1 1 0 0 0 0 .984Z" />
                                                      </svg>
                                                    </div>
                                                    <span className="hidden lg:block text-zinc-200 text-xs">
                                                      Pogledaj vježbu
                                                    </span>
                                                  </Button>
                                                  <Modal
                                                    show={
                                                      openModal === exercise.id
                                                    }
                                                    onClose={() =>
                                                      setOpenModal(null)
                                                    }
                                                  >
                                                    <Modal.Header>
                                                      {exercise.title}
                                                      {exercise.alternative && ( // Ovo će osigurati da se sljedeći blok renderira samo ako postoji exercise.alternative
                                                        <div
                                                          className="flex items-center mt-2 p-2 text-xs text-gray-800 rounded-lg bg-zinc-100"
                                                          role="alert"
                                                        >
                                                          <svg
                                                            className="w-4 h-4"
                                                            aria-hidden="true"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="currentColor"
                                                            viewBox="0 0 24 24"
                                                          >
                                                            <path
                                                              fill-rule="evenodd"
                                                              d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm9.4-5.5a1 1 0 1 0 0 2 1 1 0 1 0 0-2ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4c0-.6-.4-1-1-1h-2Z"
                                                              clip-rule="evenodd"
                                                            />
                                                          </svg>
                                                          <span className="ml-1">
                                                            Za ovu vježbu
                                                            postoji i
                                                            alternativa.
                                                          </span>
                                                          <span
                                                            onClick={() =>
                                                              setOpenModal(
                                                                exercise.alternative
                                                              )
                                                            }
                                                            className="cursor-pointer font-bold underline ml-1"
                                                          >
                                                            Pogledaj alternativu
                                                          </span>
                                                        </div>
                                                      )}
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                      <div className="">
                                                        <div className="relative pt-[56.25%]">
                                                          <iframe
                                                            src={`https://iframe.mediadelivery.net/embed/59823/${exercise.video_id}?autoplay=true&loop=true&muted=true&preload=true`}
                                                            loading="lazy"
                                                            className="rounded-lg"
                                                            style={{
                                                              border: "0",
                                                              position:
                                                                "absolute",
                                                              top: "0",
                                                              height: "100%",
                                                              width: "100%",
                                                            }}
                                                            allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                                                            allowFullScreen={
                                                              true
                                                            }
                                                          ></iframe>
                                                        </div>
                                                      </div>
                                                    </Modal.Body>
                                                  </Modal>
                                                </div>
                                              </div>
                                            ))}
                                      </Accordion.Content>
                                    </Accordion.Panel>
                                  ))}
                            </Accordion>
                          </Accordion.Content>
                        </Accordion.Panel>
                      ))}
                  </Accordion>
                ) : (
                  <></>
                )}
                {isPurchased ? (
                  <TrainingComments user={user} training_id={id} />
                ) : (
                  <></>
                )}
              </div>

              <div className="relative md:w-[30%]">
                <div className="mx-auto md:shadow-lg rounded-xl md:w-full bg-zinc-800 text-center md:text-left p-2 md:p-6 md:border border-zinc-900 sticky top-4">
                  {!isPurchased && !withSubscription ? (
                    <>
                      <p className="block text-white text-3xl font-bold py-2">
                        €{trainingData.price}
                      </p>
                      <div className="flex flex-col">
                        <span className="text-sm text-white">
                          Jednokratno plaćanje
                        </span>
                        <span className="text-xs text-zinc-400">
                          PDV uključen u cijenu
                        </span>
                      </div>
                      <span className="hidden md:block w-full bg-zinc-100 h-1 rounded-lg my-2"></span>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="w-full grid grid-cols-3 py-6">
                    <div className="flex flex-col justify-center content-center items-center">
                      <div className="flex flex-col bg-zinc-700 rounded-full w-20 h-20 rounded-full flex justify-center content-center items-center">
                        <span className="font-bold text-lg text-white">
                          {trainingData.ukupno_tjedana}
                        </span>
                        <span className="font-normal text-sm text-white">
                          tjedna
                        </span>
                      </div>
                      <span className="text-sm text-white text-center">
                        Ukupno trajanje
                      </span>
                    </div>
                    <div className="flex flex-col justify-center content-center items-center">
                      <div className="flex flex-col bg-zinc-700 rounded-full w-20 h-20 rounded-full flex justify-center content-center items-center">
                        <span className="font-bold text-lg text-white">
                          {trainingData.sesija_tjedno}
                        </span>
                        <span className="font-normal text-sm text-white">
                          treninga
                        </span>
                      </div>
                      <span className="text-sm text-white text-center">
                        Treninga tjedno
                      </span>
                    </div>
                  </div>

                  <span className="hidden md:block w-full bg-zinc-100 h-1 rounded-lg my-2"></span>
                  {isPurchased ? (
                    <>
                      <ul className="block text-sm text-white w-full mt-6 mb-6">
                        {filesDataArray.map((fileData) => (
                          <a
                            key={fileData.id}
                            target="_blank"
                            href={fileData.url}
                            rel="noopener noreferrer"
                          >
                            <li className="mb-3 flex items-center space-x-4 bg-zinc-700 hover:bg-zinc-600 p-4 rounded-lg cursor-pointer">
                              <svg
                                className="w-6 h-6 text-zinc-100"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M4.5 11H4v1h.5a.5.5 0 0 0 0-1ZM7 5V.13a2.96 2.96 0 0 0-1.293.749L2.879 3.707A2.96 2.96 0 0 0 2.13 5H7Zm3.375 6H10v3h.375a.624.624 0 0 0 .625-.625v-1.75a.624.624 0 0 0-.625-.625Z" />
                                <path d="M19 7h-1V2a1.97 1.97 0 0 0-1.933-2H9v5a2 2 0 0 1-2 2H1a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h1a1.969 1.969 0 0 0 1.933 2h12.134c1.1 0 1.7-1.236 1.856-1.614a.988.988 0 0 0 .07-.386H19a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM4.5 14H4v1a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1h1.5a2.5 2.5 0 1 1 0 5Zm8.5-.625A2.63 2.63 0 0 1 10.375 16H9a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1.375A2.63 2.63 0 0 1 13 11.625v1.75ZM17 12a1 1 0 0 1 0 2h-1v1a1 1 0 0 1-2 0v-5a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-1v1h1Z" />
                              </svg>
                              <span className="font-semibold">
                                {fileData.title}
                              </span>
                            </li>
                          </a>
                        ))}
                      </ul>
                      <span className="hidden md:block w-full bg-zinc-100 h-1 rounded-lg my-2"></span>
                    </>
                  ) : (
                    <>
                      {withSubscription ? (
                        <>
                          <a
                            onClick={() => setOpenSubscriptionModal(true)}
                            className="block w-full cursor-pointer text-center font-bold m-auto px-12 py-3 text-sm border border-black rounded-lg bg-white text-black"
                          >
                            Postani Premium član
                          </a>
                          <SubscriptionModal
                            user={user}
                            show={openSubscriptionModal}
                            onClose={() => setOpenSubscriptionModal(false)}
                          />
                        </>
                      ) : (
                        <a
                          className="block w-full text-center font-bold m-auto px-12 py-3 text-sm border border-black rounded-lg bg-white text-black"
                          href={`/app/checkout/training/${id}`}
                        >
                          Kupi odmah
                        </a>
                      )}

                      <div className="pt-3 text-center flex flex-row itmes-center justify-center">
                        <img
                          src="https://www.svgrepo.com/show/126582/warning.svg"
                          className="w-3 opacity-80 invert"
                        />

                        {withSubscription ? (
                          <span className="block text-xs font-semibold ml-2 text-zinc-400">
                            Uključeno u Premium pretplatu
                          </span>
                        ) : (
                          <span className="block text-xs font-semibold ml-2 text-zinc-400">
                            Nije uključeno u Premium pretplatu
                          </span>
                        )}
                      </div>
                    </>
                  )}
                  <div className="hidden text-sm text-white w-full mt-3 md:mt-6 hidden">
                    <p className="text-xs">
                      Kako bi vaš novac bio siguran, nikad ne obavljate novčane
                      transakcije niti komunicirajte izvan stranice.
                      <a href="#" className="font-bold underline">
                        Saznajte više
                      </a>
                    </p>
                  </div>
                  <div className="flex flex-row justify-around hidden">
                    <div className="py-3 flex flex-row items-center cursor-pointer rounded-xl bg-slate-50 hover:bg-slate-100 px-4">
                      <img
                        src="https://www.svgrepo.com/show/84870/link.svg"
                        className="w-4 mr-2"
                        alt=""
                      />
                      <p className="text-sm text-black font-semibold">
                        Copy link
                      </p>
                    </div>
                    <div className="py-3 flex flex-row items-center cursor-pointer rounded-xl bg-slate-50 hover:bg-slate-100 px-4">
                      <img
                        src="https://www.svgrepo.com/show/35048/email.svg"
                        className="w-4 mr-2"
                        alt=""
                      />
                      <p className="text-sm text-black font-semibold">
                        Send email
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default TrainingDetail;
