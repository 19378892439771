// LoginBox.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { LOGIN_URL } from "../../api/APIlogin.js";

const clientId_google = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const redirectUri_google = encodeURIComponent(
  process.env.REACT_APP_GOOGLE_REDIRECT_URI
);
const clientId_facebook = process.env.REACT_APP_FACEBOOK_CLIENT_ID; // Uobičajeno je definisati ovakve konstante van funkcije ili ih učitati iz konfiguracije
const redirectUri_facebook = encodeURIComponent(
  process.env.REACT_APP_FACEBOOK_REDIRECT_URI
);
const state_facebook = encodeURIComponent("st=state123abc,ds=123456789");

const LoginBox = () => {
  const [authorizationCode, setAuthorizationCode] = useState(null);
  const [authorizationDone, setAuthorizationDone] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loginError, setLoginError] = useState(false); // Dodano stanje za grešku

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Dodajemo funkciju za prijavu koristeći e-mail i lozinku
  const handleEmailLogin = async (e) => {
    e.preventDefault(); // Sprečavanje defaultnog reloadanja forme
    setLoginError(false);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/emaillogin`,
        formData
      );
      localStorage.setItem("accessToken", response.data.data.email_token); // Spremanje tokena u localStorage
      localStorage.setItem("accessPlatform", "email");
      setIsLoggedIn(true);
      window.location.href = "/app";
    } catch (error) {
      console.error("Login failed:", error.response?.data || error.message);
      setLoginError(true);
    }
  };

  const handleGoogleLogin = () => {
    setLoading(true);
    const googleOAuthUrl =
      `https://accounts.google.com/o/oauth2/auth` +
      `?client_id=${clientId_google}` +
      `&redirect_uri=${redirectUri_google}` +
      `&scope=openid%20profile%20email` +
      `&response_type=code`;

    window.location.href = googleOAuthUrl;
  };

  const handleFacebookLogin = () => {
    setLoading(true);
    const facebookOAuthUrl =
      `https://www.facebook.com/v19.0/dialog/oauth` +
      `?client_id=${clientId_facebook}` +
      `&redirect_uri=${redirectUri_facebook}` +
      `&state=${state_facebook}` +
      `&response_type=code` + // Ako očekuješ authorization code, moraš dodati ovaj parametar
      `&scope=email,public_profile`; // Definiši obim dozvola koje tražiš

    window.location.href = facebookOAuthUrl;
  };

  const verifyGoogleAccessToken = async (accessToken) => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${accessToken}`
      );
      const tokenInfo = response.data;
      return tokenInfo;
    } catch (error) {
      console.error(
        "Greška pri proveri valjanosti pristupnog tokena:",
        error.message
      );
      throw new Error("Pristupni token nije validan.");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const done = urlParams.get("done");
    const code = urlParams.get("code");

    if (code) {
      setAuthorizationCode(code);
      setAuthorizationDone(done);
    }
  }, []);

  useEffect(() => {
    if (authorizationCode) {
      setLoading(true);
      axios
        .post(`${LOGIN_URL}`, {
          done: authorizationDone,
          code: authorizationCode,
        })
        .then((response) => {
          const accessToken = response.data.accessToken;
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("accessPlatform", authorizationDone);
          setIsLoggedIn(true);
          window.location.href = "/app";
        })
        .catch((error) => {
          console.error(
            "Greška pri slanju autorizacijskog koda na server:",
            error.message,
            authorizationDone,
            authorizationCode
          );
        });
    }
  }, [authorizationCode]);

  useEffect(() => {
    const checkAccessToken = async () => {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        try {
          const tokenInfo = await verifyGoogleAccessToken(accessToken);
          setIsLoggedIn(true);
          window.location.href = "/app";
        } catch (error) {
          console.error(
            "Greška pri proveri valjanosti pristupnog tokena:",
            error.message
          );
        }
      } else {
        console.log("Pristupni token nije pronađen u localStorage-u.");
      }
    };
    checkAccessToken();
  }, [authorizationCode]);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    setIsLoggedIn(false);
  };

  useGSAP(() => {
    gsap.to(".login-box", {
      opacity: 1,
      ease: "power3.inOut",
      duration: 2,
      delay: 2,
    });
  });

  return (
    <div className="login-box fixed top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] z-50 opacity-0">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-white"
        >
          <img
            src="https://medianfiles.com/wp-content/uploads/2024/01/trasparent.png"
            className="mr-3 h-12"
            alt="FlowBite Logo"
          />
        </a>
      </div>
      <div className="w-full rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
        <div className="relative w-full max-w-md max-h-full">
          <div className="relative bg-main-color rounded-lg shadow">
            <div className="px-6 py-4 border-b rounded-t w-96">
              <div>
                <h1 className="text-white text-crimson text-3xl font-bold leading-tight tracking-tight md:text-3xl text-center text-bison">
                  Prijavi se
                </h1>
              </div>
            </div>
            <div className="px-6 pb-4">
              <div class="p-4 md:p-5">
                <form class="space-y-4" onSubmit={handleEmailLogin}>
                  <div>
                    <label
                      for="email"
                      class="block mb-2 text-sm font-medium text-white"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      class="border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-white border-white placeholder-gray-400"
                      placeholder="name@company.com"
                      required
                    />
                  </div>
                  <div>
                    <label
                      for="password"
                      class="block mb-2 text-sm font-medium text-white"
                    >
                      Lozinka
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="••••••••"
                      class="border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-white border-white placeholder-gray-400"
                      required
                    />
                  </div>
                  <div class="hidden flex justify-between">
                    <div class="flex items-start">
                      <a href="#" class="text-sm hover:underline text-white">
                        Zaboravili ste lozinku?
                      </a>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="text-center w-full bg-white flex items-center justify-center p-2 text-base font-semibold rounded-lg border group hover:shadow"
                  >
                    Prijavi se
                  </button>
                  {loginError && (
                    <div
                      className="flex items-center p-2 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50"
                      role="alert"
                    >
                      <span className="font-medium text-xs">
                        Neispravan email ili lozinka zaporka
                      </span>
                    </div>
                  )}
                  <div class="text-sm text-white">
                    <a
                      href="/app/registration"
                      class="text-white font-medium hover:underline"
                    >
                      Kreiraj novi korisnički račun
                    </a>
                  </div>
                </form>
              </div>

              <ul className="my-4 space-y-3 mx-4">
                <li>
                  <button
                    onClick={handleGoogleLogin}
                    className="w-full bg-white flex items-center p-2 text-base font-semibold rounded-lg border group hover:shadow"
                    disabled={loading}
                  >
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22.1 12.2272C22.1 11.5182 22.0364 10.8363 21.9182 10.1818H12.5V14.05H17.8818C17.65 15.3 16.9455 16.3591 15.8864 17.0682V19.5772H19.1182C21.0091 17.8363 22.1 15.2727 22.1 12.2272Z"
                        fill="#4285F4"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.4998 21.9999C15.1998 21.9999 17.4635 21.1045 19.118 19.5772L15.8862 17.0681C14.9907 17.6681 13.8453 18.0227 12.4998 18.0227C9.89529 18.0227 7.69075 16.2636 6.90439 13.8999H3.56348V16.4908C5.20893 19.759 8.59075 21.9999 12.4998 21.9999Z"
                        fill="#34A853"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.90455 13.9C6.70455 13.3 6.59091 12.6591 6.59091 12C6.59091 11.3409 6.70455 10.7 6.90455 10.1V7.50909H3.56364C2.88636 8.85909 2.5 10.3864 2.5 12C2.5 13.6136 2.88636 15.1409 3.56364 16.4909L6.90455 13.9Z"
                        fill="#FBBC05"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.4998 5.97727C13.968 5.97727 15.2862 6.48182 16.3226 7.47273L19.1907 4.60455C17.4589 2.99091 15.1953 2 12.4998 2C8.59075 2 5.20893 4.24091 3.56348 7.50909L6.90439 10.1C7.69075 7.73636 9.89529 5.97727 12.4998 5.97727Z"
                        fill="#EA4335"
                      ></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Log in with Google
                    </span>
                    {loading && (
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-gray-200 animate-spin fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    )}
                  </button>
                </li>
                <li>
                  <button
                    onClick={handleFacebookLogin}
                    className="w-full bg-white flex items-center p-2 text-base font-semibold rounded-lg border group hover:shadow"
                    disabled={loading}
                  >
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12.5" cy="12" r="10" fill="white"></circle>
                      <path
                        d="M22.5 12C22.5 6.477 18.023 2 12.5 2C6.977 2 2.5 6.477 2.5 12C2.5 16.991 6.157 21.128 10.938 21.878V14.891H8.398V12H10.938V9.797C10.938 7.291 12.43 5.907 14.715 5.907C15.808 5.907 16.953 6.102 16.953 6.102V8.562H15.693C14.45 8.562 14.063 9.333 14.063 10.125V12H16.836L16.393 14.89H14.063V21.878C18.843 21.128 22.5 16.991 22.5 12Z"
                        fill="#1877F2"
                      ></path>
                    </svg>

                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Log in with Facebook
                    </span>
                    {loading && (
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-gray-200 animate-spin fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    )}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginBox;
