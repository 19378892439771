// ChallengeList.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../style/style.css";
import fetchChallenges from "../../api/fetchChallenge";
import SubscriptionModal from "../Subscription/SubscriptionModal";
import ChallengeListCard from "./ChallengeListCard";

const ChallengeList = ({ user }) => {
  const [bootyPurchaseData, setBootyPurchaseData] = useState(null);
  const [challenges, setChallenges] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!user || !user.id) return; // Proverite da li user ili user.id postoje

    const fetchData = async () => {
      try {
        const purchasedResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/user/${user.id}/purchased?product_id=15&product_category=challenge`
        );
        if (purchasedResponse.data.status === "success") {
          setBootyPurchaseData(purchasedResponse.data);
        }
      } catch (err) {
        console.error("Error fetching purchased data:", err);
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchChallenges.get(`/`);
        // Proverite strukturu odgovora
        if (
          response.data &&
          response.data.data &&
          response.data.data.challenges
        ) {
          setChallenges(response.data.data.challenges);
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      } catch (err) {
        console.error("Error fetching challenges:", err);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {challenges.length > 0 ? (
        <div className="grid gap-8 lg:grid-cols-4">
          {challenges
            .filter((challenge) => challenge && challenge.id)
            .sort((a, b) => a.id - b.id)
            .map((challenge) =>
              user && user.id ? (
                <a key={challenge.id} href={`/app/challenges/${challenge.id}`}>
                  <ChallengeListCard challenge={challenge} />
                </a>
              ) : (
                <div key={challenge.id} onClick={() => setOpenModal(true)}>
                  <ChallengeListCard challenge={challenge} />
                </div>
              )
            )}
        </div>
      ) : (
        <p>No challenge available.</p>
      )}
      <SubscriptionModal
        user={user}
        show={openModal}
        onClose={() => setOpenModal(false)}
      />
    </div>
  );
};

export default ChallengeList;
