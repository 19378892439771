// App.jsx
import React, { useState, useEffect } from "react";

import axios from "axios";
import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import IndexPage from "./components/IndexPage";

import Terms from "./components/Terms";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import Registration from "./components/Login/Registration";
import Checkout from "./components/Checkout/Checkout";
import OrderConfirmation from "./components/Checkout/OrderConfirmation";
import ChallengeList from "./components/Challenges/Challenges";
import ChallengeDetail from "./components/Challenges/ChallengeDetail";
import TrainingList from "./components/Trainings/Trainings";
import TrainingDetail from "./components/Trainings/TrainingDetail";
import EbookList from "./components/Ebooks/Ebooks";
import EbookDetail from "./components/Ebooks/EbookDetail";
import VideoPlayer from "./components/Viewer/VideoPlayer";
import PdfViewer from "./components/Viewer/PdfViewer";
import Header from "./components/Header";
import Footer from "./components/Footer";

import TabelOne from "./components/Analytics/TabelOne";
import TabelTwo from "./components/Analytics/TabelTwo";
import TabelThree from "./components/Analytics/TabelThree";
import TabelFour from "./components/Analytics/TabelFour";

import fetchUserbyemail from "./api/fetchUserbyemail";

import "./style/tailwind.css";
import "flowbite";

const App = () => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [checkingToken, setCheckingToken] = useState(true);
  const verifyGoogleAccessToken = async (accessToken) => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${accessToken}`
      );
      const tokenInfo = response.data;
      return tokenInfo;
    } catch (error) {
      console.error(
        "Greška pri proveri valjanosti pristupnog tokena:",
        error.message
      );
      throw new Error("Pristupni token nije validan.");
      localStorage.removeItem("userEmail");
    }
  };
  const verifyFacebookAccessToken = async (accessToken) => {
    try {
      const response = await axios.get(
        ` https://graph.facebook.com/me?fields=id,name,email&access_token=${accessToken}`
      );
      const tokenInfo = response.data;
      return tokenInfo;
    } catch (error) {
      console.error(
        "Greška pri proveri valjanosti pristupnog tokena:",
        error.message
      );
      throw new Error("Pristupni token nije validan.");
      localStorage.removeItem("userEmail");
    }
  };
  const verifyJWTToken = async (token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/verifytoken`,
        { token }
      );
      const tokenInfo = response.data;
      return tokenInfo;
    } catch (error) {
      console.error(
        "Greška pri proveri valjanosti JWT tokena:",
        error.response?.data || error.message
      );

      throw new Error("JWT token nije validan.");
    }
  };

  const checkAccessToken = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const accessPlatform = localStorage.getItem("accessPlatform");

    if (accessToken) {
      try {
        if (accessPlatform === "google") {
          const tokenInfo = await verifyGoogleAccessToken(accessToken);
          const userEmail = tokenInfo.email;
          const response = await fetchUserbyemail.get(`/${userEmail}`);
          setUser(response.data.data.user);
          setIsLoggedIn(true);
        }
        if (accessPlatform === "facebook") {
          const tokenInfo = await verifyFacebookAccessToken(accessToken);
          const userEmail = tokenInfo.email;
          const response = await fetchUserbyemail.get(`/${userEmail}`);
          setUser(response.data.data.user);
          setIsLoggedIn(true);
        }
        if (accessPlatform === "email") {
          const tokenInfo = await verifyJWTToken(accessToken);
          const userEmail = tokenInfo.user.email;
          const response = await fetchUserbyemail.get(`/${userEmail}`);
          setUser(response.data.data.user);
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.error(
          "Greška pri proveri valjanosti pristupnog tokena:",
          error.message
        );
        setUser(null);
        setIsLoggedIn(false);
        localStorage.removeItem("userEmail");
      } finally {
        setCheckingToken(false);
      }
    } else {
      console.log("Pristupni token nije pronađen u localStorage-u.");
      setUser(null);
      setIsLoggedIn(false);
      setCheckingToken(false);
      localStorage.removeItem("userEmail");
    }
  };

  useEffect(() => {
    checkAccessToken();
  }, []); // Empty dependency array to run once during mount

  if (checkingToken) {
    // Render loading or a spinner while checking token
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Header user={user} />
      <Routes>
        <Route
          path="/app/login"
          element={isLoggedIn ? <Navigate to="/app" /> : <Login />}
        />
        <Route
          path="/app/registration"
          element={isLoggedIn ? <Navigate to="/app" /> : <Registration />}
        />
        <Route
          path="/"
          element={isLoggedIn ? <Navigate to="/app" /> : <IndexPage />}
        />
        <Route path="/terms" element={isLoggedIn ? <Terms /> : <Terms />} />
        <Route
          path="/app"
          element={
            isLoggedIn ? (
              <Dashboard user={user} />
            ) : (
              <Navigate to="/app/login" />
            )
          }
        />
        <Route
          path="/app/checkout/:category/:id"
          element={<Checkout user={user} />}
        />
        <Route
          path="/app/orderconfirmation/:order_number"
          element={
            isLoggedIn ? (
              <OrderConfirmation user={user} />
            ) : (
              <Navigate to="/app/login" />
            )
          }
        />
        <Route path="/app/orderconfirmation" element={<OrderConfirmation />} />
        <Route
          path="/app/challenges"
          element={
            isLoggedIn ? (
              <ChallengeList user={user} />
            ) : (
              <Navigate to="/app/login" />
            )
          }
        />
        <Route
          path="/app/trainings"
          element={
            isLoggedIn ? (
              <TrainingList user={user} />
            ) : (
              <Navigate to="/app/login" />
            )
          }
        />
        <Route
          path="/app/ebooks"
          element={
            isLoggedIn ? (
              <EbookList user={user} />
            ) : (
              <Navigate to="/app/login" />
            )
          }
        />
        <Route
          path="/app/trainings/:id"
          element={
            isLoggedIn ? (
              <TrainingDetail user={user} />
            ) : (
              <Navigate to="/app/login" />
            )
          }
        />
        <Route
          path="/app/challenges/:id"
          element={
            isLoggedIn ? (
              <ChallengeDetail user={user} />
            ) : (
              <Navigate to="/app/login" />
            )
          }
        />
        <Route
          path="/app/ebooks/:id"
          element={
            isLoggedIn ? (
              <EbookDetail user={user} />
            ) : (
              <Navigate to="/app/login" />
            )
          }
        />
        <Route
          path="/app/player/:id/:mediatoken"
          element={
            isLoggedIn ? (
              <VideoPlayer user={user} />
            ) : (
              <Navigate to="/app/login" />
            )
          }
        />
        <Route
          path="/app/tabelone"
          element={
            isLoggedIn ? <TabelOne user={user} /> : <Navigate to="/app/login" />
          }
        />
        <Route
          path="/app/tabeltwo"
          element={
            isLoggedIn ? <TabelTwo user={user} /> : <Navigate to="/app/login" />
          }
        />
        <Route
          path="/app/tabelthree"
          element={
            isLoggedIn ? (
              <TabelThree user={user} />
            ) : (
              <Navigate to="/app/login" />
            )
          }
        />
        <Route
          path="/app/tabelfour"
          element={
            isLoggedIn ? (
              <TabelFour user={user} />
            ) : (
              <Navigate to="/app/login" />
            )
          }
        />
      </Routes>

      <Footer user={user} />
    </Router>
  );
};

export default App;
