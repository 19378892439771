// SubscriptionBanner.jsx
import React, { useState, useEffect } from "react";
import "../../style/style.css";
import { Button, Modal } from "flowbite-react";
import fetchUser from "../../api/fetchUser";
import fetchSubscription from "../../api/fetchSubscription";
import fetchSubscriptionmetabyuser from "../../api/fetchSubscriptionmetabyuser";

import SubscriptionModal from "./SubscriptionModal";

const SubscriptionBanner = ({ user }) => {
  const [userData, setUserData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [productData, setProductData] = useState(null);
  const [selectedOption, setselectedOption] = useState("monthly");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const responseUser = await fetchUser.get(`/${user.id}`);
          setUserData(responseUser.data.data.user);
          const responseSubscription = await fetchSubscriptionmetabyuser.get(
            `/${user.id}`
          );
          if (responseSubscription.data.status === "success") {
            setSubscriptionData(responseSubscription.data.data.subscription);
          }
        }
      } catch (err) {}
    };
    fetchData();
  }, [user]);

  const handleOptionSelect = (optionId) => {
    setselectedOption(optionId);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        response = await fetchSubscription.get(`/`);
        setProductData(response.data.data);
      } catch (err) {
        console.error(`Error fetching subscriptions`, err);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {!subscriptionData ? (
        <section className="px-4 mx-auto max-w-screen-xl lg:px-6 mt-16">
          <div
            className="flex flex-col justify-center w-full p-16 pt-20 border rounded-lg shadow bg-cover bg-top border-zinc-700"
            style={{
              backgroundImage: `url(https://medianfiles.com/wp-content/uploads/2024/01/image00003-1-e1707494201388.jpeg)`,
            }}
          >
            <a href="#">
              <h5 className="text-anton mb-4 text-3xl text-white text-center">
                Postani član
              </h5>
            </a>
            <div className="flex flex-col text-center jusitfy-center gap-2">
              <div className="flex flex-col text-center jusitfy-center gap-2">
                <div className="flex justify-center items-center gap-2">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="checkmark-group--icon default-ltr-cache-4z3qvp e1svuwfo1"
                    data-name="Checkmark"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21.2928 4.29285L22.7071 5.70706L8.70706 19.7071C8.51952 19.8946 8.26517 20 7.99995 20C7.73474 20 7.48038 19.8946 7.29285 19.7071L0.292847 12.7071L1.70706 11.2928L7.99995 17.5857L21.2928 4.29285Z"
                      fill="#fff"
                    ></path>
                  </svg>
                  <span className="text-semibold text-white text-sm">
                    Preko 80+ vođenih treninga
                  </span>
                </div>
              </div>
              <div className="flex justify-center items-center gap-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="checkmark-group--icon default-ltr-cache-4z3qvp e1svuwfo1"
                  data-name="Checkmark"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.2928 4.29285L22.7071 5.70706L8.70706 19.7071C8.51952 19.8946 8.26517 20 7.99995 20C7.73474 20 7.48038 19.8946 7.29285 19.7071L0.292847 12.7071L1.70706 11.2928L7.99995 17.5857L21.2928 4.29285Z"
                    fill="#fff"
                  ></path>
                </svg>
                <span className="text-semibold text-white text-sm">
                  Kućni treninzi i treninzi za teretanu
                </span>
              </div>
              <div className="flex flex-col text-center jusitfy-center gap-2">
                <div className="flex justify-center items-center gap-2">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="checkmark-group--icon default-ltr-cache-4z3qvp e1svuwfo1"
                    data-name="Checkmark"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21.2928 4.29285L22.7071 5.70706L8.70706 19.7071C8.51952 19.8946 8.26517 20 7.99995 20C7.73474 20 7.48038 19.8946 7.29285 19.7071L0.292847 12.7071L1.70706 11.2928L7.99995 17.5857L21.2928 4.29285Z"
                      fill="#fff"
                    ></path>
                  </svg>
                  <span className="text-semibold text-white text-sm">
                    Potrebni rekviziti: bučice ili girje + elastična guma
                  </span>
                </div>
              </div>

              <div className="flex justify-center items-center gap-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="checkmark-group--icon default-ltr-cache-4z3qvp e1svuwfo1"
                  data-name="Checkmark"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.2928 4.29285L22.7071 5.70706L8.70706 19.7071C8.51952 19.8946 8.26517 20 7.99995 20C7.73474 20 7.48038 19.8946 7.29285 19.7071L0.292847 12.7071L1.70706 11.2928L7.99995 17.5857L21.2928 4.29285Z"
                    fill="#fff"
                  ></path>
                </svg>
                <span className="text-semibold text-white text-sm">
                  Popusti na sve izazove
                </span>
              </div>
              <div className="flex justify-center items-center gap-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="checkmark-group--icon default-ltr-cache-4z3qvp e1svuwfo1"
                  data-name="Checkmark"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.2928 4.29285L22.7071 5.70706L8.70706 19.7071C8.51952 19.8946 8.26517 20 7.99995 20C7.73474 20 7.48038 19.8946 7.29285 19.7071L0.292847 12.7071L1.70706 11.2928L7.99995 17.5857L21.2928 4.29285Z"
                    fill="#fff"
                  ></path>
                </svg>
                <span className="text-semibold text-white text-sm">
                  Recepti i planovi prehrane
                </span>
              </div>
              <div className="flex justify-center items-center gap-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="checkmark-group--icon default-ltr-cache-4z3qvp e1svuwfo1"
                  data-name="Checkmark"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.2928 4.29285L22.7071 5.70706L8.70706 19.7071C8.51952 19.8946 8.26517 20 7.99995 20C7.73474 20 7.48038 19.8946 7.29285 19.7071L0.292847 12.7071L1.70706 11.2928L7.99995 17.5857L21.2928 4.29285Z"
                    fill="#fff"
                  ></path>
                </svg>
                <span className="text-semibold text-white text-sm">
                  Popusti naših partnera
                </span>
              </div>
            </div>
            <Button
              onClick={() => setOpenModal(true)}
              className="mt-4 text-center mx-auto inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-button rounded-lg hover:bg-button focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              Pretplati se
              <svg
                className="w-3.5 h-3.5 ml-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </Button>
            <SubscriptionModal
              user={user}
              show={openModal}
              onClose={() => setOpenModal(false)}
            />
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default SubscriptionBanner;
