// ChallengeListCard.jsx

const ChallengeListCard = ({ challenge }) => {
  return (
    <div>
      {" "}
      <div
        className="h-80 rounded-lg shadow bg-cover bg-center flex"
        style={{ backgroundImage: `url(${challenge.image_url})` }}
      >
        <div className="relative flex flex-col items-center justify-center bg-black bg-opacity-30 hover:bg-opacity-40 rounded-lg flex-start items-end w-full h-full z-10">
          {challenge.id === 17 && (
            <div className="flex gap-2 pb-2">
              <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-red-800 text-red-100">
                Novo
              </span>
            </div>
          )}
          <h3 className="text-anton uppercase text-white text-center text-4xl mx-auto">
            {challenge.title}
          </h3>
          <span className="text-sm text-white text-center block mt-2">
            {challenge.video_count} treninga
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChallengeListCard;
