// ChallengeList.jsx
import React, { useState, useEffect } from "react";
import "../../style/style.css";
import fetchPromoCodes from "../../api/fetchPartnerPromoCodes"; // Pretpostavljamo da imate funkciju za dohvaćanje svih izazova
import fetchUser from "../../api/fetchUser";
import fetchSubscription from "../../api/fetchSubscription";
import fetchSubscriptionmetabyuser from "../../api/fetchSubscriptionmetabyuser";

const PartnerPromoCodes = ({ user }) => {
  const [promoCodes, setPromoCodes] = useState([]);
  const [userData, setUserData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const responseUser = await fetchUser.get(`/${user.id}`);
          setUserData(responseUser.data.data.user);
          const responseSubscription = await fetchSubscriptionmetabyuser.get(
            `/${user.id}`
          );
          if (responseSubscription.data.status === "success") {
            setSubscriptionData(responseSubscription.data.data.subscription);
          }
        }
      } catch (err) {}
    };
    fetchData();
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchPromoCodes.get(`/`);
        setPromoCodes(
          response.data.data.map((code) => ({ ...code, isCopied: false }))
        );
      } catch (err) {
        console.error("Error fetching challenges:", err);
      }
    };
    fetchData();
  }, []);

  const copyToClipboard = async (text, promoCodeId, promoCodeDomena) => {
    try {
      await navigator.clipboard.writeText(text);
      setPromoCodes((prevPromoCodes) =>
        prevPromoCodes.map((code) =>
          code.id === promoCodeId ? { ...code, isCopied: true } : code
        )
      );

      // Redirekcija nakon uspješnog kopiranja
      window.open(`https://${promoCodeDomena}`, "_blank");
    } catch (err) {
      console.error("Error copying to clipboard:", err);
    }
  };

  return (
    <>
      {subscriptionData && promoCodes.length > 0 ? (
        <div className="grid gap-8 lg:grid-cols-3">
          {promoCodes.map((promoCode) => (
            <div
              key={promoCode.id}
              className="max-w-sm p-6 bg-zinc-800 border border-zinc-700 rounded-lg shadow"
            >
              <div className="flex itmes-center gap-8 pb-4">
                <div className="flex items-center gap-2">
                  <img
                    className="h-24 rounded-lg"
                    src={promoCode.partner_logo}
                  />
                </div>
                <div>
                  <a href="#">
                    <h5 className="text-crimson mb-2 text-2xl font-semibold tracking-tight text-white mt-2">
                      {promoCode.title}
                    </h5>
                  </a>

                  <p className="text-xs mb-3 font-normal text-zinc-200">
                    Kopirajte i zalijepite ovaj kod na
                    <a
                      className="font-bold"
                      href={`https://www.${promoCode.domena}`}
                      target="_blank"
                    >
                      {" "}
                      {promoCode.domena}
                    </a>
                  </p>
                </div>
              </div>
              <div className="bg-zinc-900 py-4 px-4 rounded-lg border border-zinc-200 border-dashed flex justify-between items-center">
                <span className="text-white uppercase font-bold tracking-[0.1em]">
                  {promoCode.promo_code}
                </span>
                <button
                  onClick={() =>
                    copyToClipboard(
                      promoCode.promo_code,
                      promoCode.id,
                      promoCode.domena
                    )
                  }
                  className="cursor-pointer h-10 w-10 aspect-square bg-zinc-800 hover:bg-zinc-700 rounded-lg flex justify-center items-center"
                >
                  {!promoCode.isCopied ? (
                    <svg
                      className="w-6 h-6 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 8v3c0 .6-.4 1-1 1H5m11 4h2c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1h-7a1 1 0 0 0-1 1v1m4 3v10c0 .6-.4 1-1 1H6a1 1 0 0 1-1-1v-7.1c0-.3 0-.5.2-.7l2.5-2.9c.2-.2.5-.3.8-.3H13c.6 0 1 .4 1 1Z"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m5 12 4.7 4.5 9.3-9"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PartnerPromoCodes;
