import React, { useState, useEffect } from "react";
import axios from "axios";
import "./../style/style.css";

import TreningList from "./Trainings/TrainingList";
import ChallengeList from "./Challenges/ChallengeList";
import EbookList from "./Ebooks/EbookList";
import SubscriptionBanner from "./Subscription/SubscriptionBanner";
import BootyChallenge from "./Challenges/BootyChallange";
import SubscriptionPromo from "./Subscription/SubscriptionPromo";
import PartnerPromoCodes from "./Dashboard/PartnerPromoCodes";

import { Carousel } from "flowbite-react";

const IndexPage = () => {
  return (
    <>
      <nav className="border-zinc-200 z-50 w-full scrolled">
        <div className="flex flex-wrap items-center justify-between mx-2 lg:mx-12 p-2">
          <a href="/" className="flex items-center">
            <img
              src="https://medianfiles.com/wp-content/uploads/2024/01/trasparent.png"
              className="h-12 mr-3"
              alt="BetterYouGym Logo"
            />
          </a>
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-3xl lg:text-4xl font-extrabold tracking-tight leading-none text-white md:text-xl lg:text-3xl text-crimson">
              Better You
            </h1>
            <p className="text-base font-semibold text-white lg:text-sm sm:px-16 xl:px-48 uppercase tracking-wide">
              Online Gym
            </p>
          </div>
          <div className="flex md:order-2">
            <a
              href="/app"
              className="inline-flex justify-center items-center py-2 px-5 text-base font-medium text-center text-color rounded-lg bg-white"
            >
              Prijavi se
            </a>
          </div>
        </div>
      </nav>

      <div className="flex flex-col md:flex-row items-start justify-between px-4 mx-auto max-w-screen-xl lg:px-6 p-2">
        <SubscriptionPromo />
        <BootyChallenge />
      </div>

      <SubscriptionBanner />

      <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
        <h2 className="text-anton mt-12 text-4xl md:text-4xl text-white">
          Treninzi
        </h2>
        <div className="text-md text-white mb-8">(uključeno u članarinu)</div>
        <ChallengeList user="0" />
      </div>

      <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
        <h2 className="text-anton mt-12 text-4xl md:text-4xl text-white">
          Ebooks
        </h2>
        <div className="text-md text-white mb-8">(uključeno u članarinu)</div>
        <EbookList />
      </div>

      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 shadow">
        <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
          <Carousel slideInterval={2500}>
            <img
              src="https://medianfiles.com/wp-content/uploads/2024/03/WhatsApp-Image-2024-02-17-at-14.15.47.jpeg"
              alt="..."
            />
          </Carousel>
        </div>
      </div>

      <footer className="rounded-lg shadow m-4 lg:m-12 bg-zinc-800" id="footer">
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
          <span className="text-sm sm:text-center text-zinc-400">
            © 2024
            <a href="https://flowbite.com/" className="hover:underline px-1">
              Better You Online Gym -
            </a>
            All Rights Reserved.
          </span>
          <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-zinc-400 sm:mt-0">
            <li>
              <a href="/terms" className="mr-4 hover:underline md:mr-6">
                Opći uvjeti korištenja
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default IndexPage;
