// LoginBox.js
import React, { useState } from "react";
import axios from "axios";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const RegistrationBox = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/emailregister`,
        formData
      );
      if (response.data.status === "success") {
        setMessage("Registracija uspješna!");
        localStorage.setItem("accessToken", response.data.data.token); // Spremanje tokena u localStorage
        localStorage.setItem("accessPlatform", "email");
        setIsLoggedIn(true);
        window.location.href = "/app";
      } else {
        setMessage("Registracija nije uspjela.");
      }
    } catch (error) {
      setMessage("Došlo je do greške prilikom registracije.");
      console.log(error);
    }
  };

  return (
    <div className="fixed top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] z-50">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-white"
        >
          <img
            src="https://medianfiles.com/wp-content/uploads/2024/01/trasparent.png"
            className="mr-3 h-12"
            alt="FlowBite Logo"
          />
        </a>
      </div>
      <div className="w-full rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
        <div className="relative w-full max-w-md max-h-full">
          <div className="relative bg-main-color rounded-lg shadow">
            <div className="px-6 py-4 border-b rounded-t min-w-96">
              <div>
                <h1 className="text-white text-crimson text-3xl font-bold leading-tight tracking-tight md:text-3xl text-center text-bison">
                  Registriraj se
                </h1>
              </div>
            </div>
            <div className="px-6 pb-4">
              <div className="p-4 md:p-5">
                <form className="space-y-4" onSubmit={handleSubmit}>
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <label
                        htmlFor="first_name"
                        className="block mb-2 text-sm font-medium text-white"
                      >
                        Ime
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        className="border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-white border-white placeholder-gray-400 text-gray-900"
                        placeholder="Upiši ime"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="last_name"
                        className="block mb-2 text-sm font-medium text-white"
                      >
                        Prezime
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        className="border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-white border-white placeholder-gray-400 text-gray-900"
                        placeholder="Upiši prezime"
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-white"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-white border-white placeholder-gray-400 text-gray-900"
                      placeholder="name@company.com"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-white"
                    >
                      Lozinka
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="mt-2 text-center w-full bg-white flex items-center justify-center p-2 text-base font-semibold rounded-lg border group hover:shadow"
                  >
                    Registriraj se
                  </button>
                </form>
                {message && (
                  <p className="text-center text-white mt-4">{message}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationBox;
